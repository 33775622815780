$theme-colors: (
  "primary": #ec6532,
  "secondary": #523d64,
  "back": #ffffff
);

@import "~bootstrap/scss/bootstrap.scss";

$primary: #ec6532;
$secondary: #523d64;
$back: #ffffff;
