.material-blue {
  background-color: #2196f3 !important;
}

.material-red {
  background-color: #f44336 !important;
}

.material-green {
  background-color: #4caf50 !important;
}

.material-teal {
  background-color: #009688 !important;
}

.material-orange {
  background-color: #ff9800 !important;
}

.material-deep-orange {
  background-color: #ff5722 !important;
}

.material-brown {
  background-color: #795548 !important;
}

.material-pink {
  background-color: #e91e63 !important;
}

.material-deep-purple {
  background-color: #673ab7 !important;
}

.material-indigo {
  background-color: #3f51b5 !important;
}

.material-cyan {
  background-color: #00bcd4 !important;
}

.material-purple {
  background-color: #9c27b0 !important;
}

.material-light-green {
  background-color: #8bc34a !important;
}

.material-amber {
  background-color: #ffc107 !important;
}

.material-yellow {
  background-color: #ffeb3b !important;
}

.material-blue-grey {
  background-color: #607d8b !important;
}

.material-amber {
  background-color: #8bc34a !important;
}
